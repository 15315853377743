import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { getCookie } from "../cookies";
export const settings = {
  dots: true,
  infinite: true,
  speed: 200,
  fade: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  centerPadding: "0",
  customPaging: (i) => (
    <button
      style={{
        width: "20px",
        height: "1px",
        padding: "2px",
        backgroundColor: "#ffffff65",
      }}
    />
  ),
  dotsClass: "slick-dots custom-dots",
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        fade: true,
        centerPadding: "10px",
      },
    },
    {
      breakpoint: 840,
      settings: {
        dots: false,
      },
    },
  ],
};

const MainSlider = ({ sliderCurrentPage, bannerCurrentPage }) => {
  const { t, i18n } = useTranslation();
  const slides = [
    // {
    //   id: "DepositEarly",
    //   src: getCookie("i18next") === 'th' ? "/images/deposit-early-th.webp" : "/images/deposit-early-en.webp",
    //   mobileSrc: getCookie("i18next") === 'th' ? "/images/deposit-early-th-mobile.webp" : "/images/deposit-early-en-mobile.webp",
    //   href:"",
    //   newWindow: false,
    //   hideContent: true,
    //   showOnPages: ["all"],
    //   disableOnPages: [],
    //   overlay: false,
    //   hideButton: true
    // },
    {
      id: "PgsoftGlobalCashTournament",
      src: getCookie("i18next") === 'th' ? "/images/promotion/SBO568WinTHBGlobalTournamentFeb_1903_550_th.webp" : "/images/promotion/SBO568WinTHBGlobalTournamentFeb_1903_550_en.webp",
      mobileSrc: getCookie("i18next") === 'th' ? "/images/promotion/SBO568WinTHBGlobalTournamentFeb_700_450_th.webp" : "/images/promotion/SBO568WinTHBGlobalTournamentFeb_700_450_en.webp",
      href: getCookie("i18next") === 'th' ? "https://public.pgsoft-games.com/events/feb/thb/?l=th" : "https://public.pgsoft-games.com/events/feb/thb/?l=en",
      newWindow: true,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    // {
    //   id: "BngBetRace",
    //   src: getCookie("i18next") === 'th' ? "/images/promotion/BetRace1903x550_th.webp" : "/images/promotion/BetRace1903x550_en.webp",
    //   mobileSrc: getCookie("i18next") === 'th' ? "/images/promotion/BetRace700x450_th.webp" : "/images/promotion/BetRace700x450_en.webp",
    //   href: getCookie("i18next") === 'th' ? "https://servicebooongo.com//bngevent/eventinfo?lang=th" : "https://servicebooongo.com//bngevent/eventinfo?lang=en",
    //  newWindow: true,
    //  hideContent: true,
    //   showOnPages: ["all"],
    //   disableOnPages: [],
    //   overlay: false,
    //   hideButton: true
    // },
    // {
    //   id: "yggmas",
    //   src: getCookie("i18next") === 'th' ? "/images/promotion/ygg-mas-tree-th.webp" : "/images/promotion/ygg-mas-tree-en.webp",
    //   mobileSrc: getCookie("i18next") === 'th' ? "/images/promotion/ygg-mas-tree-th-mobile.webp" : "/images/promotion/ygg-mas-tree-en-mobile.webp",
    //   href:  "/promotion/ygg-mas-tree/",
    //   newWindow: false,
    //   hideContent: true,
    //   showOnPages: ["all"],
    //   disableOnPages: [],
    //   overlay: false,
    //   hideButton: true
    // },

    {
      id: "PlayAndWin",
      src: getCookie("i18next") === 'th' ? "/images/promotion/play-and-win-dec-th.webp" : "/images/promotion/play-and-win-dec-en.webp" ,
      mobileSrc: getCookie("i18next") === 'th' ? "/images/promotion/play-and-win-dec-th-mobile.webp" : "/images/promotion/play-and-win-dec-en-mobile.webp" ,
      href: "/promotion/spadegaming-play-and-win/",
      newWindow: false,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    {
      id: "Sboweeklymission",
      src: getCookie("i18next") === 'th' ? "/images/promotion/SportsPromotionBanner-TH-1903x550.webp" : "/images/promotion/SportsPromotionBanner-EN-1903x550.webp",
      mobileSrc: getCookie("i18next") === 'th' ? "/images/promotion/SportsPromotionBanner-TH-700x450.webp" : "/images/promotion/SportsPromotionBanner-EN-700x450.webp",
      href: "/promotion/sbo-weekly-mission/",
      newWindow: false,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    // {
    //   id: "DomainSwitch",
    //   src: getCookie("i18next") === 'th' ? "/images/domain_switch_1903x550_th.webp" : "/images/domain_switch_1903x550_en.webp",
    //   mobileSrc: getCookie("i18next") === 'th' ? "/images/domain_switch_700x450_th.webp" : "/images/domain_switch_700x450_en.webp",
    //   href: "#",
    //   newWindow: false,
    //   hideContent: true,
    //   showOnPages: ["all"],
    //   disableOnPages: [],
    //   overlay: false,
    //   hideButton: true
    // },
    {
      id: "DailyWins",
      src: "/images/promotion/DailyWins_1903x550.webp",
      mobileSrc: "/images/promotion/DailyWins_700x450.webp",
      href: "/promotion/daily-wins/",
      newWindow: false,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    {
      id: "RewardPoints",
      src: "/images/reward-banner.webp",
      mobileSrc: "/images/reward-banner-small.jpg",
      href: "/promotion/reward-points/",
      newWindow: false,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    {
      id: "ReferralBonus",
      src: "/images/referral-banner.webp",
      mobileSrc: "/images/referralbonus.jpg",
      href: "/promotion/referral-bonus/",
      newWindow: false,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    {
      id: "DepositBonus",
      src: "/images/deposit-banner.webp",
      mobileSrc: "/images/deposit-banner-small.jpg",
      href: "/promotion/deposit-bonus/",
      newWindow: false,
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true
    },
    
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);

  const handleLinkClick = (slideData) => {
    if (slideData.newWindow == false){
    window.location.href = slideData.href; 
    }else
    {
      window.open(slideData.href)
    }
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 840);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getFilteredSlides = (slides, sliderCurrentPage) => {
    if (sliderCurrentPage === "RewardPoints") {
      const rewardSlide = slides.find((slide) => slide.id === "RewardPoints");
      return rewardSlide ? [rewardSlide] : [];
    } else if (sliderCurrentPage === "Sboweeklymission") {
      const SboSlide = slides.find((slide) => slide.id === "Sboweeklymission");
      return SboSlide ? [SboSlide] : [];
    } else if (sliderCurrentPage === "ReferralBonus") {
      const referralSlide = slides.find((slide) => slide.id === "ReferralBonus");
      return referralSlide ? [referralSlide] : [];
    } else if (sliderCurrentPage === "DepositBonus") {
      const depositSlide = slides.find((slide) => slide.id === "DepositBonus");
      return depositSlide ? [depositSlide] : [];
    }else if (sliderCurrentPage === "JiliSuperTournaments") {
      const JILISuperTournamentSlide = slides.find((slide) => slide.id === "JILISuperTournaments");
      return JILISuperTournamentSlide ? [JILISuperTournamentSlide] : [];
    } else if (sliderCurrentPage === "BngBetRace") {
      const BngBetRaceSlide = slides.find((slide) => slide.id === "BngBetRace");
      return BngBetRaceSlide ? [BngBetRaceSlide] : [];
    } else if (sliderCurrentPage === "DailyWins") {
      const DailyWins = slides.find((slide) => slide.id === "DailyWins");
      return DailyWins ? [DailyWins] : [];
    } else if (sliderCurrentPage === "PlayAndWin") {
      const DailyWins = slides.find((slide) => slide.id === "PlayAndWin");
      return DailyWins ? [DailyWins] : [];
    } else if (sliderCurrentPage === "yggmas") {
      const DailyWins = slides.find((slide) => slide.id === "yggmas");
      return DailyWins ? [DailyWins] : [];
    } else if (sliderCurrentPage === "all") {
      return slides;
    } else {
      return slides.filter(
        (slide) =>
          slide.showOnPages.includes("all") ||
          slide.showOnPages.includes(sliderCurrentPage)
      );
    }
  };

  const filteredSlides = getFilteredSlides(slides, sliderCurrentPage);

  const activeSlideIndex = filteredSlides.findIndex(
    (slide) => slide.id === bannerCurrentPage
  );

  if (activeSlideIndex > 0) {
    const [activeSlide] = filteredSlides.splice(activeSlideIndex, 1);
    filteredSlides.unshift(activeSlide);
  }

  const renderSlide = (slide, index) => {
    const Tag = index === 0 ? "h1" : "h2";
    const imgSrc = isMobile && slide.mobileSrc ? slide.mobileSrc : slide.src;
    const isSlideInMobile = isMobile && ["JILI"].includes(slide.id);
    const textColor = { color: isSlideInMobile ? "#fff" : slide.textColor };
    const isSlideInContent =
      isMobile && ["CashBack", "ReferralBonus"].includes(slide.id);

    return (
      <div>
        <a 
        onClick={() => handleLinkClick(slide)}
        key={slide.id}
        className="slide-container"
        style={{ display: "block", textDecoration: "none", color: "inherit" }}
      >
        <div
          className={`hero-banner-wrapper heroBannerWrapper-${slide.position}`}
          style={textColor}
        >
          <Tag
            className={`headingTag ${index === 0 ? "active-slide" : ""} ${
              slide.hideContent || isSlideInContent ? "hidden" : ""
            }`}
          >
            {slide.alt}
          </Tag>
          {!slide.hideContent && !isSlideInContent && (
            <>
              <p className="slide-description">{slide.description}</p>
              {!slide.hideButton && (
                <a onClick={() => handleLinkClick(slide)} className="heroBtn">
                  {slide.buttonText}
                </a>
              )}
            </>
          )}
        </div>
        {isMobile && slide.overlay && <div className="overlay"></div>}
        <img src={imgSrc} alt={slide.alt} className="slide-image" />
        </a>
      </div>
    );
  };

  return (
    <div className="sg8-hero-banner">
      <Slider {...settings}>
        {filteredSlides.map((slide, index) => renderSlide(slide, index))}
      </Slider>
    </div>
  );
};

export default MainSlider;